<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item active>通知總覽</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="isLoading">
      <div class="card">
        <div class="card-body">
          <div class="mb-3">
            <h4 class="font-weight-bold">通知總覽</h4>
          </div>
          <div class="list-group">
            <div
              class="list-group-item cursor-pointer"
              v-for="notification in computedNotifications"
              :class="{ 'is-read': notification.is_read }"
              :key="notification.id"
              @click="handleOpenNotificationModal(notification)"
            >
              <div
                class="row"
                :class="{
                  'text-danger': notification.isImportant,
                }"
              >
                <div class="px-2" style="flex-basis: 40px">
                  <i
                    class="fa fa-exclamation-triangle"
                    v-if="notification.isImportant"
                  ></i>
                </div>
                <div class="px-2">{{ notification.displayDate }}</div>
                <div class="px-2">{{ notification.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="10"
          align="center"
          @change="fetchBranchNotifications"
        ></b-pagination>
      </div>
    </b-overlay>
    <NotificationModal
      :show="isOpenNotificationModal"
      @update:show="isOpenNotificationModal = $event"
      :notification="notification"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import notification from "@/apis/notification";
import NotificationModal from "./NotificationModal";

export default {
  components: { NotificationModal },
  data() {
    return {
      notifications: [],
      notification: {},
      currentPage: 1,
      totalRows: 1,
      isLoading: false,
      isOpenNotificationModal: false,
    };
  },
  computed: {
    ...mapGetters("general", ["currentBranch"]),
    computedNotifications() {
      return this.notifications.map((n) => {
        n.displayDate = format(new Date(n.publish_at), "yyyy-MM-dd HH:mm");
        n.isImportant = n.notify_type === "important";
        return n;
      });
    },
  },
  created() {
    this.fetchBranchNotifications();
  },
  methods: {
    async fetchBranchNotifications(page) {
      this.isLoading = true;
      try {
        const params = {
          page: page || this.currentPage,
        };
        const { data } = await notification.getBranchNotifications(
          this.currentBranch.id,
          params
        );

        this.notifications = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        alert("取得通知錯誤");
      }
      this.isLoading = false;
    },

    handleOpenNotificationModal(notification) {
      this.notification = notification;
      this.$router.push({
        name: "BranchNotificationDetail",
        params: {
          notification_id: notification.id,
        }
      })
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.list-group-item {
  font-weight: 600;
}
.list-group-item.is-read {
  background-color: #f5f5f5;
  font-weight: normal;
}
</style>
